import { mountedDisassembleAppsEssBillingDetail } from "../helpers/spa/spa.helper";
import { InvoiceConceptsForServiceType } from "./InvoiceConceptsForServiceType";
type openDialogInformation = () => void;

function isInvoiceConceptForServiceType(serviceTypeName: string): boolean {
  return Object.values(InvoiceConceptsForServiceType).includes(
    serviceTypeName as InvoiceConceptsForServiceType
  );
}

export const getDialogComponentByName = (
  serviceTypeName: string,
  invoiceId: string,
  openDialogInformation: openDialogInformation
) => {
  if (serviceTypeName !== undefined && serviceTypeName !== null) {
    if (isInvoiceConceptForServiceType(serviceTypeName)) {
      return mountedDisassembleAppsEssBillingDetail(serviceTypeName, invoiceId);
    } else {
      return openDialogInformation();
    }
  }
};
